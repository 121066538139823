<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <content-not-view
      v-else-if="!subPermission.store || !subPermission.update"
    />
    <b-card :title="$t('g.basicInformations')" v-else>
      <!-- form -->
      <validation-observer ref="form">
        <b-form>
          <b-row>
            <!--Name of document -->
            <b-col md="4">
              <b-form-group :label="$t('g.documentName')">
                <validation-provider
                  #default="{ errors }"
                  name="document name"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.doc_name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheDocumentNameHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Name of document -->
            <!-- Document Code -->
            <b-col md="4">
              <b-form-group :label="$t('g.documentCode')">
                <validation-provider
                  #default="{ errors }"
                  name="document code"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.doc_number"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheDocumentCodeHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Document Code -->
            <!-- Issue No. -->
            <b-col md="4">
              <b-form-group :label="$t('g.issueNumber')">
                <validation-provider
                  #default="{ errors }"
                  name="issue number"
                  rules="required"
                >
                  <b-form-input
                    type="number"
                    v-model="form_data.release_number"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheIssueNumberHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Issue No. -->
            <!-- Issue date -->
            <b-col md="4">
              <b-form-group :label="$t('g.issueDate')">
                <validation-provider
                  #default="{ errors }"
                  name="issue date"
                  rules="required"
                >
                  <b-form-datepicker
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.release_date"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Issue date -->
            <!-- Source -->
            <b-col md="4">
              <b-form-group :label="$t('g.source')">
                <validation-provider
                  #default="{ errors }"
                  name="source"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.source_name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheSourceHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Source -->
            <!-- Review number -->
            <b-col md="4">
              <b-form-group :label="$t('g.reviewNumber')">
                <validation-provider
                  #default="{ errors }"
                  name="review number"
                  rules="required"
                >
                  <b-form-input
                    type="number"
                    v-model="form_data.review_number"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheReviewNumberHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Review number -->
            <!-- Review date -->
            <b-col md="4">
              <b-form-group :label="$t('g.reviewDate')">
                <validation-provider
                  #default="{ errors }"
                  name="review date"
                  rules="required"
                >
                  <b-form-datepicker
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.review_date"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Review date -->
            <!-- Document place -->
            <b-col md="4">
              <b-form-group :label="$t('g.documentPlace')">
                <validation-provider
                  #default="{ errors }"
                  name="document place"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.doc_location"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheDocumentPlaceHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Document place -->
            <!-- Document retention period -->
            <b-col md="4">
              <b-form-group :label="$t('g.documentRetentionPeriod')">
                <validation-provider
                  #default="{ errors }"
                  name="document retention period"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.saving_duration"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="
                      $t('g.placeholder.enterTheDocumentRetentionPeriodHere')
                    "
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Document retention period -->
            <!-- Remind Before Days -->
            <b-col md="4">
              <b-form-group :label="$t('g.reviewNumber')">
                <validation-provider
                  #default="{ errors }"
                  name="review number"
                  rules="required"
                >
                  <b-form-input
                    type="number"
                    v-model="form_data.remind_before_days"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheReviewNumberHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Remind Before Days -->
            <!-- Suspension or Withdrawal date -->
            <b-col md="4">
              <b-form-group :label="$t('g.SuspensionOrWithdrawalDate')">
                <validation-provider
                  #default="{ errors }"
                  name="suspension or Withdrawal date"
                  rules="required"
                >
                  <b-form-datepicker
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.holding_date"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Suspension or Withdrawal date -->
            <!-- Upload Files  -->
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="upload files"
                  rules="required"
                >
                  <label for="files">{{ $t("g.uploadFiles") }}</label>
                  <b-form-file
                    :placeholder="$t('g.placeholder.chooseAFileOrDropItHere')"
                    :drop-placeholder="$t('g.dropFileHere')"
                    :state="errors.length > 0 ? false : null"
                    multiple
                    v-model="form_data.doc_file"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Upload Files -->
            <b-col md="6" v-if="isEdit">
              <div class="d-flex align-items-center h-100">
                <a
                  v-if="subPermission.show"
                  :href="form_data.path"
                  class="text-primary"
                  target="_blank"
                >
                  {{ $t("g.viewDocument") }}
                </a>
              </div>
            </b-col>
            <!-- Notes -->
            <b-col md="12">
              <b-form-group :label="$t('g.notes')">
                <b-form-textarea
                  id="textarea-default"
                  :placeholder="$t('g.placeholder.enterTheNotesHere')"
                  v-model="form_data.notes"
                  rows="6"
                />
              </b-form-group>
            </b-col>
            <!-- ** Notes -->
            <!-- Submit Button -->
            <b-col cols="12">
              <b-button
                :variant="isEdit ? 'success' : 'primary'"
                type="submit"
                @click.prevent="validationForm"
              >
                {{ isEdit ? $t("g.edit") : $t("g.submit") }}
              </b-button>
            </b-col>
            <!-- ** Submit Button -->
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BCard,
  BFormFile,
  BSpinner,
  BFormTextarea,
  BFormDatepicker,
} from "bootstrap-vue";
import { required, email, phone, password, confirmed, min } from "@validations";
import pagesData from "./pagesData";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
import store from "@/store/index";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormFile,
    ContentNotView,
    Loading,
    Error,
    BFormTextarea,
    BFormDatepicker,
  },
  data() {
    return {
      form_data: {
        doc_name: null,
        doc_number: null,
        release_number: null,
        release_date: null,
        source_name: null,
        review_number: null,
        review_date: null,
        doc_location: null,
        saving_duration: null,
        holding_date: null,
        notes: null,
        doc_file: null,
        remind_before_days: null,
        path: null,
      },
      isEdit: false,
      isLoading: true,
      isError: null,
      currentId: null,
      pageName: null,
      routePageName: null,
      subPermission: {},
    };
  },

  watch: {
    $route(to, from) {
      pagesData.forEach((page) => {
        switch (to.name) {
          case page.addName:
            this.handleWatchRoutePage(page.addName, page.list);
            break;
        }
      });
    },
  },
  created() {
    if (this.$route.params.id) {
      pagesData.forEach((page) => {
        switch (this.$route.name) {
          case page.editName:
            this.handleCreatedRoutePage(
              page.getFormDataPath,
              page.editName,
              page.list,
              page.permission
            );
            break;
        }
      });
    }
  },

  beforeMount() {
    if (!this.$route.params.id) {
      pagesData.forEach((page) => {
        switch (this.$route.name) {
          case page.addName:
            setTimeout(() => {
              this.pageStatus(false, false);
              store.dispatch("GET_PERMISSION", page.permission);
              this.subPermission = store.state.permissions.sub;
            }, 1000);

            break;
        }
      });
    }
  },

  methods: {
    // Refresh the page data when it is created for the first time
    handleCreatedRoutePage(path, pageName, routePageName, permission) {
      this.getFormData(`admin/paperTypes/${path}/papers`, permission);
      this.pageName = pageName;
      this.routePageName = routePageName;
    },
    // Update page data when it changes
    handleWatchRoutePage(pageName, routePageName) {
      this.isEdit = false;
      this.pageName = pageName;
      this.routePageName = routePageName;
      this.$refs.form.reset();
      this.clearForm();
    },
    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    // Changes the state of the page according to the return of the data => ( success Or error )
    pageStatus(loading, error) {
      this.isLoading = loading;
      this.isError = error;
    },
    //  Clear Form Data
    clearForm() {
      let form = this.form_data,
        i;
      for (i in form) {
        form[i] = null;
      }
    },
    // Get the form data when page edit
    getFormData(path, permission) {
      this.isEdit = true;
      this.currentId = this.$route.params.id;
      this.$http
        .get(`${path}/${this.currentId}`)
        .then((res) => {
          for (let key in this.form_data) {
            this.form_data[key] = res.data.data[key];
          }
          this.form_data.path = res.data.data.doc_file.path;
          setTimeout(() => {
            this.pageStatus(false, false);
            store.dispatch("GET_PERMISSION", permission);
            this.subPermission = store.state.permissions.sub;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          this.pageStatus(false, true);
        });
    },
    // Send the modified data
    submitEdit(path) {
      if (this.form_data.doc_file.path) {
        delete this.form_data.doc_file;
      }
      let formData = new FormData();
      for (let item in this.form_data) {
        if (item === "doc_file") {
          formData.append(item, this.form_data[item][0]);
        } else {
          formData.append(item, this.form_data[item]);
        }
      }

      // call Backend
      this.$http
        .post(`admin/paperTypes/${path}/papers/${this.currentId}`, formData, {
          params: { _method: "put" },
        })
        .then((res) => {
          this.makeToast(
            "success",
            this.$t("g.send.successBody"),
            this.$t("g.send.successTitle")
          );
          setTimeout(() => {
            this.$router.push({ name: this.routePageName });
          }, 500);
        })
        .catch((err) => {
          console.log(err.response.data.errors);
          for (const key in err.response.data.errors) {
            this.makeToast(
              "danger",
              err.response.data.errors[key][0],
              this.$t("g.send.errorTitle")
            );
          }
          console.log(err);
        });
    },
    //  Submit new user
    submitNewAdditon(path, routePageName) {
      this.$refs.form.validate().then((success) => {
        if (success) {
          // append data to FormData object before sending to backend
          let formData = new FormData();
          for (let item in this.form_data) {
            if (item === "doc_file") {
              formData.append(item, this.form_data[item][0]);
            } else {
              formData.append(item, this.form_data[item]);
            }
          }

          // call backend - $http is an axios prototype
          this.$http
            .post(`admin/paperTypes/${path}/papers`, formData)
            .then((res) => {
              this.makeToast(
                "success",
                this.$t("g.send.successBody"),
                this.$t("g.send.successTitle")
              );
              setTimeout(() => {
                this.$router.push({ name: routePageName });
              }, 1000);
            })
            .catch((err) => {
              console.log(err.response.data.errors[0]);
              for (const key in err.response.data.errors) {
                this.makeToast(
                  "danger",
                  err.response.data.errors[key][0],
                  this.$t("g.send.errorTitle")
                );
              }
              console.log(err);
            });
        } else {
          console.log(!success);
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
    //  Submit Function
    validationForm() {
      pagesData.forEach((page) => {
        switch (this.$route.name) {
          case page.addName:
            this.submitNewAdditon(page.sendNewDataPath, page.list);
            break;
          case page.editName:
            this.submitEdit(page.sendEditDataPath);
            break;
        }
      });
    },
  },
};
</script>
<style>
.loading-div {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
