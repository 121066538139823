var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('loading'):(_vm.isError)?_c('error'):(!_vm.subPermission.store || !_vm.subPermission.update)?_c('content-not-view'):_c('b-card',{attrs:{"title":_vm.$t('g.basicInformations')}},[_c('validation-observer',{ref:"form"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.documentName')}},[_c('validation-provider',{attrs:{"name":"document name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.enterTheDocumentNameHere')},model:{value:(_vm.form_data.doc_name),callback:function ($$v) {_vm.$set(_vm.form_data, "doc_name", $$v)},expression:"form_data.doc_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.documentCode')}},[_c('validation-provider',{attrs:{"name":"document code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.enterTheDocumentCodeHere')},model:{value:(_vm.form_data.doc_number),callback:function ($$v) {_vm.$set(_vm.form_data, "doc_number", $$v)},expression:"form_data.doc_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.issueNumber')}},[_c('validation-provider',{attrs:{"name":"issue number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.enterTheIssueNumberHere')},model:{value:(_vm.form_data.release_number),callback:function ($$v) {_vm.$set(_vm.form_data, "release_number", $$v)},expression:"form_data.release_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.issueDate')}},[_c('validation-provider',{attrs:{"name":"issue date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.release_date),callback:function ($$v) {_vm.$set(_vm.form_data, "release_date", $$v)},expression:"form_data.release_date"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.source')}},[_c('validation-provider',{attrs:{"name":"source","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.enterTheSourceHere')},model:{value:(_vm.form_data.source_name),callback:function ($$v) {_vm.$set(_vm.form_data, "source_name", $$v)},expression:"form_data.source_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.reviewNumber')}},[_c('validation-provider',{attrs:{"name":"review number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.enterTheReviewNumberHere')},model:{value:(_vm.form_data.review_number),callback:function ($$v) {_vm.$set(_vm.form_data, "review_number", $$v)},expression:"form_data.review_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.reviewDate')}},[_c('validation-provider',{attrs:{"name":"review date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.review_date),callback:function ($$v) {_vm.$set(_vm.form_data, "review_date", $$v)},expression:"form_data.review_date"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.documentPlace')}},[_c('validation-provider',{attrs:{"name":"document place","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.enterTheDocumentPlaceHere')},model:{value:(_vm.form_data.doc_location),callback:function ($$v) {_vm.$set(_vm.form_data, "doc_location", $$v)},expression:"form_data.doc_location"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.documentRetentionPeriod')}},[_c('validation-provider',{attrs:{"name":"document retention period","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.enterTheDocumentRetentionPeriodHere')},model:{value:(_vm.form_data.saving_duration),callback:function ($$v) {_vm.$set(_vm.form_data, "saving_duration", $$v)},expression:"form_data.saving_duration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.reviewNumber')}},[_c('validation-provider',{attrs:{"name":"review number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.enterTheReviewNumberHere')},model:{value:(_vm.form_data.remind_before_days),callback:function ($$v) {_vm.$set(_vm.form_data, "remind_before_days", $$v)},expression:"form_data.remind_before_days"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.SuspensionOrWithdrawalDate')}},[_c('validation-provider',{attrs:{"name":"suspension or Withdrawal date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.holding_date),callback:function ($$v) {_vm.$set(_vm.form_data, "holding_date", $$v)},expression:"form_data.holding_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("g.alert." + errors[0]) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"upload files","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"files"}},[_vm._v(_vm._s(_vm.$t("g.uploadFiles")))]),_c('b-form-file',{attrs:{"placeholder":_vm.$t('g.placeholder.chooseAFileOrDropItHere'),"drop-placeholder":_vm.$t('g.dropFileHere'),"state":errors.length > 0 ? false : null,"multiple":""},model:{value:(_vm.form_data.doc_file),callback:function ($$v) {_vm.$set(_vm.form_data, "doc_file", $$v)},expression:"form_data.doc_file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),(_vm.isEdit)?_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex align-items-center h-100"},[(_vm.subPermission.show)?_c('a',{staticClass:"text-primary",attrs:{"href":_vm.form_data.path,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("g.viewDocument"))+" ")]):_vm._e()])]):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.notes')}},[_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":_vm.$t('g.placeholder.enterTheNotesHere'),"rows":"6"},model:{value:(_vm.form_data.notes),callback:function ($$v) {_vm.$set(_vm.form_data, "notes", $$v)},expression:"form_data.notes"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":_vm.isEdit ? 'success' : 'primary',"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" "+_vm._s(_vm.isEdit ? _vm.$t("g.edit") : _vm.$t("g.submit"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }