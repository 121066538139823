<template>
  <b-row>
    <b-col cols="12">
      <div class="loading-text">
        <div class="text-center">
          <b-spinner
            class="spinner-loading"
            variant="primary"
            label="Text Centered"
          />
          <h2 class="text-primary d-inline mr-1 ml-1">{{ $t("g.loading") }}</h2>
          <h3 class="loading-des mt-1">
            {{ $t("g.Please wait while we look up this data for you") }}
          </h3>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BSpinner } from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BSpinner,
  },
};
</script>
